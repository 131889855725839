import HomePage from "static/js/app/pages/home";
import SearchPage from "static/js/app/pages/search";
import StaticSearchPage from "static/js/app/pages/searchStatic";
import VehiclePage from "static/js/app/pages/vehicle";
import SoldGallery from "static/js/app/pages/sold";
import Common from "static/js/app/pages/common";
import ComparisonPage from "static/js/app/pages/compare";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { HugoSiteConfig } from "static/js/app/hugoSettings/hugoSiteConfig";
import { HugoPageConfig } from "static/js/app/hugoSettings/hugoPageConfig";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import { VehiclePageConfig } from "static/js/app/hugoSettings/pageConfigs/vehiclePageConfig";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import ContactPage from "static/js/app/pages/contact";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import WebsectionGallery from "static/js/app/pages/websectionGallery";
import BranchGallery from "static/js/app/pages/branchGallery";
import NewVehiclePage from "static/js/app/pages/newvehicle";

export class App
{
    public static Run(
        hugoSiteConfigJson: string,
        hugoPageConfigJson: string,
        dealerBranchesJson: string
    ) {
        let hugoSiteConfig: HugoSiteConfig = new HugoSiteConfig(JSON.parse(hugoSiteConfigJson));
        let hugoPageConfig: HugoPageConfig = new HugoPageConfig(JSON.parse(hugoPageConfigJson));
        let dealerBranches: DealerBranchPublicInfo[] = JSON.parse(dealerBranchesJson)
            .map((branch: any) => new DealerBranchPublicInfo(branch));

        const siteConfig = SiteConfig.FromHugoSiteConfig(hugoSiteConfig, dealerBranches);
        const pageConfig = PageConfig.FromHugoPageConfig(hugoPageConfig);

        Common.Init(siteConfig, pageConfig);

        switch (pageConfig.jsPageId) {
            case 'home':
                Common.PageReady(siteConfig, pageConfig);
                HomePage.init(siteConfig, pageConfig);
                Breadcrumbs.displayBreadcrumbs();
                break;
            case 'vehicles':
                Common.PageReady(siteConfig, pageConfig);
                SearchPage.init(siteConfig, pageConfig, () => Breadcrumbs.displayBreadcrumbs(),pageConfig.vehicleStatus ?? siteConfig.searchConfig.default_vehicle_status);
                break;
            case 'vehicles-static':
                    Common.PageReady(siteConfig, pageConfig);
                    StaticSearchPage.init(siteConfig, pageConfig,pageConfig.vehicleStatus ?? siteConfig.searchConfig.default_vehicle_status);
                    break;
                             
            case 'sold-vehicles':
                Common.PageReady(siteConfig, pageConfig);
                SoldGallery.init(siteConfig, pageConfig, () => Breadcrumbs.displayBreadcrumbs());
                break;
            case 'vehicle':
                const vehiclePageConfig = VehiclePageConfig.FromHugoPageConfig(hugoPageConfig);
                Common.PageReady(siteConfig, vehiclePageConfig);
                VehiclePage.init(siteConfig, vehiclePageConfig, function() {
                    Breadcrumbs.displayBreadcrumbs();
                    (<any>$(document)).foundation();
                    if(document.location.href.indexOf("#") != -1) {
                        document.location.href = document.location.href;  // trick browsers into following to the page anchor even when that anchor is inside a script template
                     }
                    Common.debounceFormSubmitButtons(); // has to be run again for forms embedded in script templates
                });
                break;
            case 'contact':
                Common.PageReady(siteConfig, pageConfig);
                ContactPage.init(siteConfig, pageConfig);
                Breadcrumbs.displayBreadcrumbs();
                break;
            case 'compare':
                Common.PageReady(siteConfig, pageConfig);
                ComparisonPage.init(siteConfig);
                Breadcrumbs.displayBreadcrumbs();
                break;
            case 'websection-vehicles':
                Common.PageReady(siteConfig, pageConfig);
                WebsectionGallery.init(siteConfig, pageConfig, () => Breadcrumbs.displayBreadcrumbs());
                break;
            case 'branch-vehicles':
                Common.PageReady(siteConfig, pageConfig);
                BranchGallery.init(siteConfig, pageConfig, () => Breadcrumbs.displayBreadcrumbs());
                break;
            case 'new-vehicles':
                Common.PageReady(siteConfig, pageConfig);
                NewVehiclePage.init(siteConfig, () => Breadcrumbs.displayBreadcrumbs());
                break;
            default:
                Common.PageReady(siteConfig, pageConfig);
                Breadcrumbs.displayBreadcrumbs();
                break;
        }
    }
}
